import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";
import Select from "react-select";
import styled from "styled-components";
import "./ExternalQuestionPopUp.scss";
import cogoToast from 'cogo-toast';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete'
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from "@mui/material/CircularProgress";
import greenCircleTick from "../../../../../assets/images/green-circle-tick.svg";
import greyCircleTick from "../../../../../assets/images/grey-circle-tick.svg";
import greenSquareTick from "../../../../../assets/images/green-square-tick.svg";
import greySquareTick from "../../../../../assets/images/grey-square-tick.svg";
import deleteImage from "../../../../../assets/images/delete-question.svg";
import addImage from "../../../../../assets/images/image-add.svg";
import addCode from "../../../../../assets/images/code-fill.svg";
import "./ExternalQuestionForm.scss";
import { ReactQuillComponent } from "../../../InterviewGuides/Components/CommonReactQuill";
import  { isMCQQuestionType, isMSQQuestionType, isIntQuestionType, getOptionLabelByInputLabel } from '../../Constants'
import { getImageAcceptedFormats, validateImageFile } from "../../../../../Utils/ImageUtils";


const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: '1200px',
    maxWidth: '1200px !important',
    maxHeight: '700px',
    borderRadius: '14px',
  },
  dialogContent:{
      width:'100%'
  }
}))

const FormSelect = styled(Select)`
  display: inline-block;
  padding: 0;
  border-radius: 4px;
  width: 250px !important;
  align-content: center;
  > div {
    margin-left: 0;
    background-color: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.23);
  }
`;

const FormSelectTopic = styled(Select)`
  display: inline-block;
  padding: 0;
  border-radius: 8px;
  width: 350px !important;
  align-content: center;
  margin-left:4px;
  margin-bottom:16px;
  > div {
    margin-left: 0;
    background-color: #F0F3FF;
    border: 1px solid rgba(0, 0, 0, 0.23);
  }
`;

const renderRadioButton = () => {
  return <Radio
      style={{ width: "30px", padding: 0, margin: '0px 0px 2px 0px' }}
      sx={{
          '&.Mui-checked': {
              color: '#123fbc',
          },
          '& .MuiSvgIcon-root': {
              fontSize: 20,
              padding: 0
          },
      }} />
}

const renderTextfields = (label, isOptional, name, value, handleChange, isMultiline, isDisabled) => {

  return (
      <>
          <Typography className='question-titles-technical'> {label}  <span>{isOptional ? ' (Optional)' : ""}</span> </Typography>          
          <TextField
              disabled={isDisabled}
              multiline={isMultiline}
              maxRows={isMultiline ? 2 : 1}
              rows={isMultiline ? 2 : 1}
              name={name}
              defaultValue={value}
              onChange={handleChange}
              variant="outlined"
              sx={{
                  padding: "0px",
                  "& legend": {
                      display: "none"
                  },
              }}
              inputProps={{
                  style: {
                      padding: "8px",
                  },
              }}
          />
      </>
  )
}

const renderReactQuill = (label, isOptional, name, value, handleChange, isMultiline, isDisabled, uploadFileV2) => {

    return (
        <>
            <Typography className='question-titles-technical'> {label}  <span>{isOptional ? ' (Optional)' : ""}</span> </Typography>          
            <Box paddingBottom="8px">
            <ReactQuillComponent
              name={name}
              value={value}
              handleChange={handleChange}
              uploadFileV2={uploadFileV2}
              isDisabled={isDisabled}
            />
          </Box>
        </>
    )
  }

const renderIntegerField = (formData, formStatus, handleChange) => {
    return (
        <Box className="integer-box">
            <span className="ans-label">Answers</span>
            <TextField
                size="small"
                id="outlined-secondary"
                variant="outlined"
                className="ans-field"
                name="integerAnswer"
                sx={{ "& legend": { display: "none" } }}
                type="number"
                disabled={formStatus === 'VIEW'}
                value={formData?.integerAnswer}
                onChange={(e) => handleChange(e)}
            />
        </Box>
    )
}

const renderRadioFields = (label, formData, formStatus, handleOptionSelect, handleOptionChange, addOption, deleteOption) => {
    return (
        <>
         <Box className="option-div fs-12">
              <Box className={formData?.isError && "requireMark"} fontWeight="600" marginBottom="5px">{label}</Box>
              {Object.keys(formData?.options)?.map((option, i) => {
                  return (
                      <Box display="flex" alignItems="center">
                          <Box className="option-container">
                            <Box onClick={(e) =>formStatus !== 'VIEW' && handleOptionSelect(formData?.options?.[option], option)}>
                               <img src={formData?.answer_json?.length > 0 && formData?.answer_json?.indexOf(getOptionLabelByInputLabel(option)) > -1 ? greenCircleTick : greyCircleTick} />
                            </Box>
                             <TextField
                                  size="small"
                                  id="outlined-secondary"
                                  variant="outlined"
                                  name="option_text"
                                  sx={{ "& legend": { display: "none" } }}
                                  className="option-div-text"
                                  multiline
                                  disabled={formStatus === 'VIEW'}
                                  value={formData?.options[option]}
                                  onChange={(e) =>
                                      handleOptionChange(e, option, i)
                                  }
                              />
                          </Box>
                          {Object.keys(formData?.options)?.length > 3 && formStatus !=='VIEW' && (
                              <Box onClick={() => deleteOption(option)} className="cursor-p" >
                                  <img src={deleteImage} />
                              </Box>
                          )}
                      </Box>
                  );
              })}
              {Object.keys(formData?.options)?.length < 10 && formStatus !=='VIEW' && <Box onClick={() => addOption()} className="addoption">
                  + New Option
              </Box>}
          </Box>
        </>
    )
}

const renderCheckboxFields = (label, formData, formStatus, handleMultiOptionSelect, handleOptionChange, addOption, deleteOption) => {
    return (
        <>
         <Box className="option-div fs-12">
              <Box fontWeight="600" marginBottom="5px">{label}</Box>
              {Object.keys(formData?.options).map((option, i) => {
                  return (
                      <Box display="flex" alignItems="center">
                          <Box className="option-container">
                            <Box onClick={(e) =>formStatus !== 'VIEW' && handleMultiOptionSelect(formData?.options?.[option], option)}>
                               <img src={formData?.answer_json?.length > 0 && formData?.answer_json?.indexOf(getOptionLabelByInputLabel(option)) > -1? greenSquareTick : greySquareTick} />
                            </Box>
                             <TextField
                                  size="small"
                                  id="outlined-secondary"
                                  variant="outlined"
                                  name="option_text"
                                  sx={{ "& legend": { display: "none" } }}
                                  className="option-div-text"
                                  multiline
                                  disabled={formStatus === 'VIEW'}
                                  value={formData?.options[option]}
                                  onChange={(e) =>
                                      handleOptionChange(e, option, i)
                                  }
                              />
                          </Box>
                          {Object.keys(formData?.options)?.length > 3 && formStatus !=='VIEW' && (
                              <Box onClick={() => deleteOption(option)} className="cursor-p" >
                                  <img src={deleteImage} />
                              </Box>
                          )}
                      </Box>
                  );
              })}
              {Object.keys(formData?.options)?.length < 10 && formStatus !=='VIEW' && <Box onClick={() => addOption()} className="addoption">
                  + New Option
              </Box>}
          </Box>
        </>
    )
}


const RenderExternalQuestionForm = ({
    formData, 
    setFormData, 
    formStatus, 
    questionTypeList, 
    defaultMarkingList, 
    topicList, 
    uploadFileV2,
    selectedDefaultMarkData, 
    setSelectedDefaultMarkData,
    skillListOptions,
    selectedSkill,
    setSelectedSkill,
    fileUpload,
    menuIsOpen,
    setMenuIsOpen
}) => {
  const [changedKeyword, setChangedKeyWord] = useState("");
  const [uploadedImageName, setUploadedImageName] = useState();
  const [imageLoader, setImageLoader] = useState(false);
  const queryString = require("query-string");

  const handleChange = (e) => {
      const copyData = { ...formData };
      const { name, value } = e?.target;

      {
          name === "questionText" ? copyData.questionText = value :
              name === "questionCode" ? copyData.questionCode = value :
                  name === "difficulty" ? copyData.difficultyLevel = value :
                  name === "integerAnswer" ? copyData.integerAnswer = value :
                      ``
      }

      setFormData(copyData);
  }

  const handleSkillChange = (e) => {
    setSelectedSkill(e);
  }

  const handleQuillChange = (name, value) => {
    const copyData = { ...formData };
    {
        name === "questionText" ? copyData.questionText = value : ``
    }
    setFormData(copyData);
}

  const handleQuestionTypeChange = (e) => {
    const copyData = { ...formData };
    {e?.name === "type" ? copyData.type = e?.value : ''}
    copyData.answer_json = [];
    setFormData(copyData);
  }

  useEffect(()=>{
    if(formData.type && defaultMarkingList) {
        const result = Object.keys(defaultMarkingList)?.filter((data)=>{
            return formData?.type == data;
        })
        setSelectedDefaultMarkData(defaultMarkingList[result] || []);
    } else {
        setSelectedDefaultMarkData([]);
    }
  },[formData.type, defaultMarkingList])

  useEffect(()=>{
    if(formData?.difficultyLevel && (!formData?.negativeMark || !formData?.positiveMark)) {
        const result = Object?.keys(selectedDefaultMarkData)?.filter((data)=>{
            return formData?.difficultyLevel == data;
        })?.[0];
        const copyData = { ...formData };
        copyData.positiveMark = selectedDefaultMarkData[result]?.positive_mark || '';
        copyData.negativeMark = selectedDefaultMarkData[result]?.negative_mark || '';
        setFormData(copyData);
    }
  },[formData.difficultyLevel, selectedDefaultMarkData, formData?.negativeMark, formData?.positiveMark])

  const handleQuestionTopicChange = (e) => {
    const copyData = { ...formData };
    copyData.questionTopic = e?.value;
    setFormData(copyData);
  }

  const handleChangeTags = (e) => {
      setChangedKeyWord(e?.target?.value);
  }

  const handleKeypressTags = (e) => {
      //it triggers by pressing the enter key
      if (e?.key === 'Enter') {
          addTag();
      }
  };

  const addTag = () => {
      if (changedKeyword.length > 0) {
          const copyData = { ...formData };
          const newTag = copyData?.tags
          newTag.push(changedKeyword)
          setFormData(copyData)
          setChangedKeyWord("")
      }
  }

  const deleteTag = (index) => {
      formData.tags.splice(index, 1)
      setFormData({ ...formData });
  }

  const handleOptionSelect = (isDataPresent, option) => {
      if (isDataPresent) {
        setFormData({
            ...formData,
            answer_json: [ getOptionLabelByInputLabel(option)]
        });
      }
  };

  const handleMultiOptionSelect = (isDataPresent, option) => {
    if (isDataPresent) {

        const optionIndex = formData?.answer_json?.indexOf(getOptionLabelByInputLabel(option))

        if ( optionIndex > -1) {
          formData?.answer_json?.splice(optionIndex, 1)
          setFormData((formData)=>({
            ...formData,
            answer_json: formData?.answer_json
          }));
        } else {
          setFormData((formData)=>({
            ...formData,
            answer_json: [...formData?.answer_json, getOptionLabelByInputLabel(option)]
          }));
        }
    }
};

  const handleOptionChange = (e, optionNumber, i) => {
      let updatedOption = {
          ...formData?.options,
          [optionNumber]: e.target.value
      };

      checkEmpty(updatedOption)
      setFormData({
          ...formData,
          options: updatedOption
      });
  };

  const deleteOption = (deletedOption) => {
      const updatedOptions = {};
      let optionMatched = false;
      Object.keys(formData?.options).forEach((optionName, i) => {
          if (deletedOption === optionName) {
              optionMatched = true;
          } else {
              if (!optionMatched) {
                  updatedOptions[optionName] = formData?.options[optionName];
              } else {
                  updatedOptions[`option${i}`] =
                      formData?.options[`option${i + 1}`];
              }
          }
      });
      checkEmpty(updatedOptions)
      setFormData({
          ...formData,
          options: updatedOptions
      });
  };

  const addOption = () => {
      const optionsLength = Object.keys(formData?.options)?.length;
      const isError = checkEmpty(formData?.options)
      if(isError){
        cogoToast.error('Please fill previous options before adding another.')
      } else {
      setFormData({
          ...formData,
          options: {
              ...formData?.options,
              [`option${optionsLength + 1}`]: ""
          }
      });}
  };

  const checkEmpty = (updatedOption) => {
      let isError = false;
      Object.keys(updatedOption).every((option) => {
          if (!updatedOption[option]) {
              isError = true;
              return false;
          } else {
              return true;
          }
      })
      if (isError) {
          setFormData({ ...formData, isError: true })
      } else {
          setFormData({ ...formData, isError: false })
      }

      return isError;
  };

  const handleImageUpload = async (e) => {
    if(!validateImageFile(e?.target?.files[0])){
        return;
      }
    
    setImageLoader(true);
      try {
          const response = await fileUpload(e?.target?.files[0]);
          const uploadedFileURL = response?.data?.fields["x-amz-meta-internal-key"];
          const uploadedFileName = response?.data?.fields["x-amz-meta-filename"];

          setImageLoader(false);
          setUploadedImageName(uploadedFileName);
          setFormData({ ...formData, imageLink: uploadedFileURL, isImageUploaded: true });
      } catch (err) {
          setImageLoader(false);
          cogoToast.error(err?.data?.msg || err?.data || "Image not uploaded, please try again");
      }
  }

  const handleRemoveCode = () => {
    setFormData({...formData, isCodeAdded: false, questionCode: ''})
  };

  const handleRemoveImage = () => {
    setFormData({...formData, isImageUploaded: false, imageLink: '', imageUrl: ''})
  };

  const handleMenuOpen = (isOpen) => {
    setMenuIsOpen(isOpen);
  };

  return (
      <Box>
          <Box display='flex' gap="20px">
              <Box>
                  <Box marginTop="10px">
                      <Typography className='question-titles-technical'>Question Type : </Typography>
                      <FormSelect
                          className="question-type-input"
                          options={questionTypeList}
                          placeholder="Select Question Type"
                          onChange={handleQuestionTypeChange}
                          name="type"
                          isSearchable
                          value={formData?.type && formData?.type?.length > 0 ? ({ "label": formData?.type, "name": "type", "value": formData?.type }) : ""}
                      />
                  </Box>

                    <Box display='flex' flexDirection='column' >
                        <Typography className='question-titles-technical'>Skill : </Typography>
                        <FormSelect
                            className="question-type-input"
                            options={skillListOptions}
                            placeholder="Select skill"
                            onChange={handleSkillChange}
                            value={selectedSkill}
                            isSearchable
                        />
                    </Box>
                  <Box display='flex' flexDirection='column' >
                      <Box>
                          <Typography className='question-titles-technical'>Assign Difficulty : </Typography>
                      </Box>
                      <RadioGroup row
                          disabled={formStatus === 'VIEW'}
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="difficulty"
                          sx={{ marginLeft: '10px' }}
                          defaultValue={formData?.difficultyLevel?.toUpperCase()}
                          onChange={handleChange}>
                          <FormControlLabel disabled={formStatus === 'VIEW'} value="EASY" control={renderRadioButton()} label={<Typography className='checkbox-label-technical'>Easy</Typography>} />
                          <FormControlLabel disabled={formStatus === 'VIEW'} value="MEDIUM" control={renderRadioButton()} label={<Typography className='checkbox-label-technical'>Medium</Typography>} />
                          <FormControlLabel disabled={formStatus === 'VIEW'} value="HARD" control={renderRadioButton()} label={<Typography className='checkbox-label-technical'>Hard</Typography>} />
                      </RadioGroup>
                  </Box>

                  <Box className="default-point-box">
                      <Typography className='default-point-head'>Default Points : </Typography>
                      <table className="points-table" >
                          <tr>
                              <td></td>
                              <td>Correct</td>
                              <td>Wrong</td>
                          </tr>
                          <tr>
                              <td>Easy</td>
                              <th>{selectedDefaultMarkData?.EASY?.positive_mark ? selectedDefaultMarkData?.EASY?.positive_mark : "- -"}</th>
                              <th>{selectedDefaultMarkData?.EASY?.negative_mark ? selectedDefaultMarkData?.EASY?.negative_mark : "- -"}</th>
                          </tr>
                          <tr>
                              <td>Medium</td>
                              <th>{selectedDefaultMarkData?.MEDIUM?.positive_mark ? selectedDefaultMarkData?.MEDIUM?.positive_mark : "- -"}</th>
                              <th>{selectedDefaultMarkData?.MEDIUM?.negative_mark ? selectedDefaultMarkData?.MEDIUM?.negative_mark : "- -"}</th>
                          </tr>
                          <tr>
                              <td>Hard</td>
                              <th>{selectedDefaultMarkData?.HARD?.positive_mark ? selectedDefaultMarkData?.HARD?.positive_mark : "- -"}</th>
                              <th>{selectedDefaultMarkData?.HARD?.negative_mark ? selectedDefaultMarkData?.HARD?.negative_mark : "- -"}</th>
                          </tr>
                      </table>
                  </Box>

                  {
                      topicList && topicList?.length > 0 ?
                          <Box marginTop="10px">
                              <Typography className='question-titles-technical'>Question Topic : </Typography>
                              <Box width="90%">
                                  <FormSelect
                                    menuIsOpen={menuIsOpen}
                                    onMenuOpen={() => handleMenuOpen(true)}
                                    onMenuClose={() => handleMenuOpen(false)}
                                      className="question-type-input"
                                      options={topicList?.map(topic => ({ label: topic?.topicName, value: topic?.topicName }))}
                                      placeholder="Select Question Topic"
                                      onChange={handleQuestionTopicChange}
                                      name="type"
                                      isSearchable
                                      value={formData?.questionTopic && formData?.questionTopic?.length > 0 ? ({ "label": formData?.questionTopic, "name": "type", "value": formData?.questionTopic }) : ""}
                                  />
                              </Box>
                          </Box>
                          : null
                  }

                  <Box marginTop="10px">
                      <Typography className='question-titles-technical'>Tags (optional) : </Typography>
                      <Box>
                          <Box display='flex' gap='5px'>
                              {formStatus !== 'VIEW' && <><TextField
                                  size="small"
                                  className="tag-textfield"
                                  maxRows={2}
                                  rows={2}
                                  name="tags"
                                  value={changedKeyword}
                                  onChange={(e) => handleChangeTags(e)}
                                  placeholder="Add tags"
                                  variant="outlined"
                                  onKeyPress={handleKeypressTags}
                                  sx={{
                                      padding: "0px",
                                      "& legend": {
                                          display: "none"
                                      },
                                      width: '400px'
                                  }}
                                  inputProps={{
                                      style: {
                                          padding: "8px",
                                          width: '400px'
                                      },
                                  }}
                              />
                                  <Box className='add-button-tag' onClick={addTag}>+</Box></>}
                          </Box>
                          <Grid className='tags-parent' container columns={10}>
                              {formData?.tags?.length > 0 ?
                                  formData?.tags?.map((tag, index) => {
                                      return (
                                          <Grid item className="tag">
                                              <Typography className="tag-text-technical">{tag}</Typography>
                                              {formStatus !== 'VIEW' && <CloseIcon className="cross-icon" onClick={() => deleteTag(index)} />}
                                          </Grid>
                                      )
                                  })
                                  : ''}
                          </Grid>
                      </Box>
                  </Box>
              </Box>

              <Box backgroundColor="#F0F3FF" minWidth="800px" >
                  <Box className="questionbg">

                      <Box>
                          <Typography className='question-titles-technical'>Question : </Typography>
                          <Box width="90%" marginTop="-20px">

                          {renderReactQuill("", false, 'questionText', formData.questionText, handleQuillChange, false, formStatus==='VIEW', uploadFileV2)}

                              {formStatus !== 'VIEW' && formData?.isCodeAdded &&
                                  <Box display="flex" justifyContent="space-between" alignItems="flex-end" marginTop="20px">
                                      <Box className="add-code-box" onClick={handleRemoveCode}>Remove Code</Box>
                                  </Box>
                              }

                              {(formStatus !== 'VIEW' || formData.questionCode) && formData.isCodeAdded &&
                                  renderTextfields("", false, 'questionCode', formData.questionCode, handleChange, true, formStatus === 'VIEW')}

                              {formData?.isImageUploaded &&
                                  <Box display="flex" gap="10px" alignItems="flex-start" justifyContent="space-between" marginTop="10px">
                                    {formData?.imageUrl ?
                                      <Box className="image-box">
                                        <img src={formData?.imageUrl} className="image-style" alt='question-image' />
                                      </Box>
                                      :
                                      <Box width="75%" className="fs-13 word-break">{formStatus === 'EDIT' ? queryString?.parse(formData?.imageLink)?.s3_key : uploadedImageName}</Box>
                                    }
                                    {formStatus !== 'VIEW' &&
                                      <Box className="add-code-box" onClick={handleRemoveImage}>Remove Image</Box>
                                    }
                                  </Box>
                              }

                              <Box className="add-code-box" margin="10px 0px" >
                                  {!formData?.isCodeAdded ? <Box onClick={() => setFormData({ ...formData, isCodeAdded: true })}><span><img src={addCode} /></span>Add Code</Box> : ''}
                                  {!formData?.isImageUploaded ? <Box>
                                      <label for={"image-upload"}><span><img src={addImage} /></span> Upload Image</label>
                                      <input type="file" id="image-upload" style={{ display: "none" }} accept={getImageAcceptedFormats()} onChange={(e) => handleImageUpload(e)} />
                                  </Box> : ''}
                                  {imageLoader ? <CircularProgress size={20} /> : ""}
                              </Box>

                              {isIntQuestionType(formData?.type) ? renderIntegerField(formData, formStatus, handleChange)
                                : isMSQQuestionType(formData?.type) ? renderCheckboxFields("Mark the Multiple Correct Answers", formData, formStatus, handleMultiOptionSelect, handleOptionChange, addOption, deleteOption)
                                : renderRadioFields("Mark Correct Answer", formData, formStatus, handleOptionSelect, handleOptionChange, addOption, deleteOption)
                              }
                          </Box>
                      </Box>
                  </Box>
              </Box>
          </Box>
      </Box>
  )
}

const ExternalQuestionPopUp = (props) => {
  const classes = useStyles();
  const { 
    formStatus, 
    editModalOpen, 
    setEditModalOpen, 
    questionData, 
    editExternalQuestionIndex, 
    submitChange, 
    questionTypeList, 
    defaultMarkingList, 
    topicList, 
    uploadFileV2,
    skillListOptions,
    fileUpload
} = props;
const [selectedDefaultMarkData, setSelectedDefaultMarkData] = useState([]);
const [menuIsOpen, setMenuIsOpen] = useState(false);
const boxRef = useRef(null);

    const [formData, setFormData] = useState({
    tagField: "",
    tags: [],
    type: "",
    difficultyLevel: "",
    questionText: "",
    questionTopic: null,
    options: { option1: '', option2: '', option3: '' },
    answer_json: [],
    selectedOption: '',
    questionCode: '',
    isError: false,
    positiveMark: '',
    negativeMark: '',
    isCodeAdded: false,
    isImageUploaded: false,
    imageLink: '',
    integerAnswer: '',
});
const [selectedSkill, setSelectedSkill] = useState();
useEffect(() => {
    if (formStatus=== 'EDIT' || formStatus === 'VIEW') {

        const editableQuestionData = questionData?.questions?.[editExternalQuestionIndex];
        const formData = {
            tags: editableQuestionData?.tags || [],
            type: editableQuestionData?.type || [],
            difficultyLevel: editableQuestionData?.difficulty_level,
            questionText: editableQuestionData?.question_text || '',
            questionCode: editableQuestionData?.question_code || '',
            isCodeAdded: editableQuestionData?.question_code ? true : false,
            questionTopic: editableQuestionData?.topic || null,
            options: editableQuestionData?.options_json,
            answer_json: editableQuestionData?.answer_json || [],
            positiveMark: editableQuestionData?.positive_mark || '',
            negativeMark: editableQuestionData?.negative_mark || '',
            skill: editableQuestionData?.skill?.id || editableQuestionData?.skill,
            integerAnswer: editableQuestionData?.answer_json[0] || '',
            imageLink: editableQuestionData?.question_image || '',
            imageUrl: editableQuestionData?.question_image_view_url || '',
            isImageUploaded: editableQuestionData?.question_image ? true : false,
        }

        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                ...formData
            };
        });
        const skillData = skillListOptions?.find(item=>item?.id === ( editableQuestionData?.skill?.id || editableQuestionData?.skill));
        setSelectedSkill(skillData);
    }
}, []);

  useEffect(() => {
       if(menuIsOpen && topicList?.length > 1) {
            let increasedHeight =  (topicList?.length * 50);
            if (boxRef.current) {
                boxRef.current.scrollTop += parseInt(increasedHeight);
              }

       }
  },[menuIsOpen])


  const handleClose = () => {
    setEditModalOpen(false);
  }

  const saveEditForm = (isEditMode) => {
    
      if ( !(formData?.questionText && formData?.questionText !== ""
        && formData?.difficultyLevel && formData?.difficultyLevel !== ""
        && formData?.type && formData?.type?.length !== 0
        && (isEditMode || // Skip these checks in edit mode
            (formData?.positiveMark && formData?.positiveMark !== ""
        && formData?.negativeMark && formData?.negativeMark !== ""))
        && (isIntQuestionType(formData?.type)? 
        formData?.integerAnswer && formData?.integerAnswer !== "" :
        (isMCQQuestionType(formData?.type) || isMSQQuestionType(formData?.type)) ?
        formData?.options?.option1 && formData?.options?.option1?.length !== 0
        && formData?.options?.option2 && formData?.options?.option2?.length !== 0
        && formData?.options?.option3 && formData?.options?.option3?.length !== 0
        && formData?.answer_json && formData?.answer_json?.length !== 0 : "")
        && selectedSkill?.value && selectedSkill?.value?.length !== 0)) {
          cogoToast.error("Please fill mandatory fields before proceeding")
          return;

        }

    const dataLoad = {
      tags: formData?.tags || null,
      type: formData?.type || null,
      difficulty_level: formData?.difficultyLevel && formData?.difficultyLevel?.length > 0 ? formData?.difficultyLevel : null,
      question_text: formData?.questionText || null,
      topic: formData?.questionTopic || null,
      question_code: formData?.questionCode || null,
      question_image: formData?.imageLink || null,
      origin: 'EXTERNAL',
      skill:selectedSkill?.data?.id,
    };

    if(isEditMode) {
          
        if((formData?.positiveMark === '' || formData?.positiveMark === null || formData?.negativeMark === '' || formData?.negativeMark === null) &&  selectedDefaultMarkData && typeof selectedDefaultMarkData === 'object' && !Array.isArray(selectedDefaultMarkData)) {
            if(formData?.difficultyLevel) {
                const result = Object?.keys(selectedDefaultMarkData)?.filter((data)=>{
                    return formData?.difficultyLevel == data;
                })
                dataLoad.positive_mark = selectedDefaultMarkData[result]?.positive_mark;
                dataLoad.negative_mark = selectedDefaultMarkData[result]?.negative_mark;
            }
        }
 }
    if(isIntQuestionType(formData?.type)){
        dataLoad.answer_json = [parseInt(formData?.integerAnswer)] || null;
      }else if(isMCQQuestionType(formData?.type) || isMSQQuestionType(formData?.type)) {
        dataLoad.options_json = {
          option1: formData?.options?.option1 || null,
          option2: formData?.options?.option2 || null,
          option3: formData?.options?.option3 || null,
          }
        dataLoad.answer_json = formData?.answer_json || null;
      }
    
    if(formData?.options?.option4 && !isIntQuestionType(formData?.type)){
        dataLoad.options_json.option4 = formData?.options?.option4
    }
    if(formData?.options?.option5 && !isIntQuestionType(formData?.type)){
        dataLoad.options_json.option5 = formData?.options?.option5
    }
    if(formData?.options?.option6 && !isIntQuestionType(formData?.type)){
        dataLoad.options_json.option6 = formData?.options?.option6
    }
    if(formData?.options?.option7 && !isIntQuestionType(formData?.type)){
        dataLoad.options_json.option7 = formData?.options?.option7
    }
    if(formData?.options?.option8 && !isIntQuestionType(formData?.type)){
        dataLoad.options_json.option8 = formData?.options?.option8
    }
    if(formData?.options?.option9 && !isIntQuestionType(formData?.type)){
        dataLoad.options_json.option9 = formData?.options?.option9
    }
    if(formData?.options?.option10 && !isIntQuestionType(formData?.type)){
        dataLoad.options_json.option10 = formData?.options?.option10
    }

    const dupQuestionData = {...questionData};

    if(formStatus === 'ADD'){
      dupQuestionData.questions.push(dataLoad)
    }else  if(formStatus === 'EDIT'){
      dupQuestionData.questions[editExternalQuestionIndex] = dataLoad;
    }
    submitChange(dupQuestionData);
    handleClose();
  }

  return (
    <Box>
      <Dialog
        open={editModalOpen}
        classes={{ paper: classes.dialogWrapper }}
        className="dialog-parent-class"
        onClose={handleClose}
      >

        <Box className="button-dialogTitle-box-technical" justifyContent={'space-between'} width={'100%'}>
          <DialogTitle className="dialog-title-technical" classes={{ paper: classes.dialogContent }}>
            <div>{formStatus === 'ADD' ? 'Add Question Externally': formStatus === 'EDIT' ? 'Edit Externally Added Question' : 'View Question'}</div>
          </DialogTitle>
          <div>
            {(formStatus === 'ADD' || formStatus === 'EDIT') && <Button className="saveButton-technical" onClick={()=> saveEditForm(formStatus === 'ADD' ? false : true)}>Save Question</Button>}
            <CloseIcon className="close-popup-technical" onClick={handleClose} />
          </div>
        </Box>

        <DialogContent 
        classes={{ paper: classes.dialogContent }}
        dividers>
        <div className="edit-external-form-parentDiv"   ref={boxRef}>
          <RenderExternalQuestionForm
          formData={formData}
          setFormData={setFormData}
          formStatus={formStatus}
          questionTypeList={questionTypeList}
          defaultMarkingList={defaultMarkingList}
          topicList={topicList}
          uploadFileV2={uploadFileV2}
          selectedDefaultMarkData={selectedDefaultMarkData}
          setSelectedDefaultMarkData={setSelectedDefaultMarkData}
          skillListOptions={skillListOptions}
          selectedSkill={selectedSkill}
          setSelectedSkill={setSelectedSkill}
          fileUpload={fileUpload}
          menuIsOpen={menuIsOpen}
          setMenuIsOpen={setMenuIsOpen}
           />
      </div>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default ExternalQuestionPopUp;